.current-orders-category{
    background-color: #f3f1eb;
    /* background-color: #dce7e2; */
    padding-bottom: 10px;
    margin: 10px;
    text-align: center;
    border-radius: 5px;
    padding: 10px 5px 5px 10px;
}

.current-orders-item{
    padding: 10px;
    margin: 5px;
    display: flex;
    justify-content: space-between;
    background-color: rgb(238, 248, 240);
    border-radius: 20px;
}

.accept-reject-button{
    display: flex;
    padding: 1rem;
    justify-content:space-around;
}
/* cart css */
.cart{
    padding: .5rem;
    margin: .5rem;
    width: 100%;
    margin-top: 0;
    margin-bottom: 6rem;
    border: 1px solid white;
    border-radius: 20px;
    background-color: white;
    align-items: center;
}

.cart_total{
    padding: 1rem;
    margin: 1rem;
    border: 1px solid white;
    border-radius: 20px;
    background-color: white;
}

.cac-o-cartitems{
    margin: 10px;
    padding: 6px;
    /* border-bottom:1px solid gainsboro; */
}

.btnqty{
 
    background-color: rgb(240, 231, 231);
    margin: 5px;
    width: 5rem;
    border:1px solid rgb(207, 78, 78);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btnqtyelse{
 
    background-color: rgb(240, 231, 231);
    margin: 5px;
    width: 5rem;
    border:1px solid rgb(207, 78, 78);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.c-o-plusminusbutton{
    background-color: transparent;
    border: none;
    font-size: 15px;
    font-weight: 500;
    color: rgb(207, 78, 78);
    padding-bottom: 5px;
}

.price_per_qty{
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: space-between;
}

.pricecart{
  font-size: 1rem;
}

.main_2_div{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100vw;
    max-height: auto;
    margin-top: 20px;
}

.cart_footer{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color:  #5d7c08;
    position:fixed;
    bottom:1px;
    margin-top: 5px;
    width: 100%;

    color: white;
}

/* animation -  */

