.navbar {
    /* background-color: #40a578; */

    background-color: black; 


    height: 70px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 20;
  }
  
  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    max-width: 1500px;
    position: relative;
  }
  
  .nav-logo {
    color: white;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
    
    display: flex;
    align-items: center;
    justify-items: center;
  }
  
  .nav-logo .icon {
    display: inline-block;
    width: 6rem;
    height: 3rem;
    margin-left: 16px;
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
  }
  
  .nav-links {
    color: #ffce32;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
  }
  /* .fa-code {
    margin-left: 1rem;
  } */
  
  .nav-item {
    line-height: 40px;
    margin-right: 1rem;
  }
  
  .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }
  
  .nav-item:hover:after {
    width: 100%;
    background: #B4D6CD;
  }
  
  .nav-item .active {
    color: #f4991a;
    border: 1px solid #f4991a;
  }
  
  .nav-icon {
    display: none;
    color: white;
  }
  
  .nav-icon .icon {
    display: inline-block;
    color: white;
    width: 2rem;
    height: 2rem;
  }
  
  @media screen and (max-width: 960px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1pxsolid #fff;
      position: absolute;
      top: 80px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      /* background: #B4D6CD; */
      background: #5f6caf;
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-item .active {
      background-color: #B4D6CD;
    
      border-radius: 40px;
      border: none;
    }
    .nav-links {
      padding: 1.5rem;
      width: 100%;
      display: table;
    }
  
    .nav-logo .icon {
      width: 2rem;
      height: 2rem;
    }
  
    .nav-icon {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-100%, -50%);
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      color: #f4991a;
    }
  }