videoCard{
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    align-items: center;


    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.orderLIst_image{
    border-radius: 10px;
    height: auto;
    width:4rem;
    margin:0;
    
}

.orderList-nameAndprice{
    width:6rem;
}

