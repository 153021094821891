.cart{
    padding: .5rem;
    margin: .5rem;
    width: 100%;
    margin-top: 0;
    margin-bottom: 6rem;
    border: 1px solid white;
    border-radius: 20px;
    background-color: white;
    align-items: center;
}

.cart_total{
    padding: 1rem;
    margin: 1rem;
    border: 1px solid white;
    border-radius: 20px;
    background-color: white;
}

.cartitems{
    margin: 10px;
    padding: 6px;
    border-bottom:1px solid gainsboro;
}

.btnqty{
 
    background-color: rgb(240, 231, 231);
    margin: 5px;
    width: 5rem;
    border:1px solid rgb(207, 78, 78);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.plusminusbutton{
    background-color: transparent;
    border: none;
    font-size: 15px;
    font-weight: 500;
    color: rgb(207, 78, 78);
    padding-bottom: 5px;
}

.price_per_qty{
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: space-between;
}

.pricecart{
  font-size: 1rem;
}

.main_2_div{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100vw;
    max-height: auto;
    margin-top: 20px;
}

.cart_footer{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color:  #668809;
    position:fixed;
    bottom:1px;
    margin-top: 5px;
    width: 100%;

    color: white;
}

/* animation -  */

