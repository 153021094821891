.sections {
  display: flex;
  align-items: center;
  width: auto;
  height: 100vh;
}
.main-div {
}

.blur {
  filter: blur(4px); /* Apply blur effect to the background */
  pointer-events: none; /* Prevent interactions with blurred elements */
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Semi-transparent white background */
}

.form {
  background-color: whitesmoke;
  width: 100%;
}

.button-1 {
  grid-area: butt1;
  background-color: rgb(19, 1, 22);
  color: whitesmoke;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.button-2 {
  grid-area: butt2;
  background-color: rgb(19, 1, 22);
  color: whitesmoke;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.button-div {
  display: grid;
  grid-template-areas: "butt1 butt2";
}

.button-div button:hover {
  background-color: rgb(80, 7, 80);
}

/* .button-div button:focus {
  background-color: whitesmoke;
  color: black;
} */

.form .nav .nav-link {
  color: white !important;
}

/* .form .nav .active {
  color: black !important;
} */

.containers {
  max-width: 100%;
  margin-top: 0.5rem;
  align-items: center;
}

.image-upload {
  width: 10rem;
  height: auto;
}

@media only screen and (min-width: 768px) {
  .main_text {
    flex-basis: 75%;
    max-width: 100%;
    padding-left: 2rem;
    align-items: center;
    display: block;
    margin: auto;
  }
}

.main_text {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn-get-started-main {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  background-color: black;
  color: white;
  border: 2px solid white;
  text-decoration: none;
}

.brand-name {
  color: #805345 !important;
}



@media only screen and (min-width: 700px) {
  .upload-first {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: black;
  }

  .upload-right {
    width: 100%;
    margin-top: 2rem;
    padding: 1rem;
    background-color: rgb(19, 1, 22);
    color: white;
  }
  .form {
    height: 100vh;
    width: 720px;
    padding: 10px;
  }

  .text-area {
    background-color: white;
    width: 380px;
    height: 250px;
    border-radius: 5px;
    padding: 0.5rem;
  }

  .chose-file {
    padding: 8px;
    background-color: whitesmoke;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 600px) {
  .upload-first {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .upload-right {
    width: 100%;
    height: auto;
    padding: 0.5rem;
    color: white;
    position: relative;
    background-color: black;
  }
  .form {
    width: 100%;
    padding: 0.5rem;
    height: auto;
  }

  .text-area {
    background-color: white;
    width: 90%;
    height: 250px;
    border-radius: 5px;
  }

  .chose-file {
    width: 90%;
    background-color: whitesmoke;
    border-radius: 5px;
  }
 
  .main_text {
    padding-left: 2%;
    width: 100vw;
  }
}

.btn-submit {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.5s;
    background-color: black;
    color: white;
    border: 2px solid white;
    text-decoration: none;
    margin: 1rem;
  }
  .btn-submit:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  }

/* ----------------------------- */
/* 
  .suggestion{
    background-color: #f0e9c5;
    padding:1rem;
    padding-left: 0;
    margin: .5rem;
    margin-left: 0;
    
  } */
