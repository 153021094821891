.business_details{
    margin:5px;
    border:1px solid black ;
    border-radius: 8px;
    padding: 1rem;
    color: rgb(178, 221, 192);
    background-color: rgb(65, 99, 99);
}


/* grid for description and image */
.menu_desc { grid-area: menudesc; }
.menu_image { grid-area: menuimage; }


.menu_item{
    display: grid;
    grid-template-areas:
    'menuimage  menudesc';
    gap: 10px;
    margin:5px;
    max-width: 100%;
    border-bottom: .5px solid gainsboro;
 
    
}

.menu_main{
 margin-bottom: 7rem;
}

.menu_image{
    border-radius: 10px;
    height: auto;
    width:6rem;
    margin:10px;
    
}
.menu_image2{
    border-radius: 10px;
    height: auto;
    width:3rem;
    margin:3px;
    
}
.menu_desc{
    padding:5px;
    margin:5px;
    width: 10rem;
}
.menu_category_value{
    padding: 10px;
    margin: 5px;
    border-bottom: 2px solid black;
}
.menu_item_none{
   display: none;
}

.menu_footer{
  display: flex;
  justify-content: space-between;
    padding: 20px;
    /* background-color: rgb(255, 115, 0); */
    background-color: #40a578;
    position:fixed;
    bottom:0;
    margin-top: 5px;
    width: 100%;
    color: white;
  
}
.menu_footer.animate-footer {
  animation: fadeInSlideUp .5s;
}

@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateX(20px);
   
  }
 
  100% {
    opacity: 1;
    transform: translateX(0);

  }
}

.animate-add {
    animation: scaleUp .25s ease-in-out;
  }
  
  @keyframes scaleUp {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  

  .horizontal_slider {
    position: fixed;
    bottom:4.5rem; /* Adjust as needed */
    left: 0;
    right: 0;
    display: flex;
    overflow-x: auto;
    background: #FFF67E; 
    z-index: 1000;
    padding: 6px;
    font-size:small;
    border-top: 1px solid #ccc; /* Adjust as needed */
  }
  
  .slider_button {
    padding: 10px 15px;
    margin: 0 15px 0 5px;
    border: none;
    color: rgb(19, 11, 11);
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s;
    flex-shrink: 0;
  }
  
  .slider_button.active {
    background-color: transparent; /* Adjust as needed */
    font-weight: 800;
    font-size:small;
  }

  .btnprice{
    display: flex;
    padding-top: 6px;
    justify-content: space-between;
    border-radius: 20px;
  }

  .btnqtymenu{
 
    background-color: rgb(240, 231, 231);
    margin: 5px;
    width: 5rem;
    border:1px solid rgb(207, 78, 78);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.plusminusbuttonmenu{
    background-color: transparent;
    border: none;
    font-size: 15px;
    font-weight: 500;
    color: rgb(207, 78, 78);
    padding-bottom: 5px;
}