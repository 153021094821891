.crmain{
    padding: 10px;
    margin: 20px;
 
}

.crmain .a{
    text-decoration: none;
    color: aliceblue;
}

.crheading{
    margin: 20px;
    margin-bottom: 20px;
    text-align: center;
    padding: 10px;
    border-top: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
}
.crpricetotal{
    display: flex;
    justify-content: space-between;
}

.order-item{
    margin:10px;
    border-bottom: 1px solid gainsboro;
}

.goback{

    display: flex;
    margin-top: 10px;
    justify-content: center;
    color: white;
    /* background-color: #668809; */
    background-color: #eb7526;
    border-radius: 10px;
    width: 100%;
    position: sticky;
    bottom: 10px;
    padding: 10px;
}

.nav-links_goback{
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
    
}

.totaltaxes{
    display: flex;
    justify-content: space-between;
}

.totla-and-taxes{
    margin-top: 20px;
}