.af_main{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    flex-direction: column;
    text-align: center;
    padding: 1rem;
    border:1px solid black;
    border-radius: 10px;
}


.blur {
    filter: blur(4px); /* Apply blur effect to the background */
    pointer-events: none; /* Prevent interactions with blurred elements */
  }

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white background */
  }


  /* Styles for the dropdown */
.dropdown {
    position: relative;
    display: inline-block;
    margin-top: 3rem;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
