.navlink{
    text-decoration: none;
    color: black;
    padding: .8rem;
    background-color: aliceblue;
    margin: .5rem;
    text-align: center;
    font-weight: 600;
    font-size:x-large;
}

.navlink-active {
    background-color: blue;
    color: white; /* Optional: change text color */
  }


  .App{
    margin: 0;
    top: 0;
    padding: 0;
  }