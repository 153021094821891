.business_details{
    margin:5px;
    border:1px solid black ;
    border-radius: 8px;
    padding: 1rem;
    color: white;
    background-color: #5f6caf;
}
.business_footer{
    padding: 20px;
    background-color:  #40a578;
    position:fixed;
    display: flex;
    justify-content: space-between;
    bottom:0;
 color: white;
    width: 100%;
    border-radius: 8px;
}

.business-main{
    margin-bottom: 5rem;
}